<template>
  <div>
    <Header-top @updataSelect="updataSelect"  @fabucangwei="fabucangwei" @hangxiangShow="hangxiangShow" ref="headerTop"></Header-top>
    <div class="main">
      <div class="userBox">
        <div class="userLeft">
          <div class="userPic">
            <p class="pic1">
              {{ userInfo.userNick ? userInfo.userNick.slice(0, 1) : "" }}
            </p>
          </div>
          <div style="margin-top: 50px">
            {{ phone }}
          </div>
          <div
            style="margin-top: 40px"
            class="userleftbox cursor"
            :class="type == 1 ? 'type1' : ''"
            @click="seelect(1)"
          >
            <svg
              t="1649381082853"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3962"
              width="14"
              height="14"
            >
              <path
                d="M512 512c123.776 0 224-100.224 224-224S635.776 64 512 64a223.936 223.936 0 0 0-224 224C288 411.776 388.224 512 512 512z m0 64c-149.504 0-448 85.76-448 256v128h896v-128c0-170.24-298.496-256-448-256z"
                :fill="type == 1 ? '#00bafd' : '#262626'"
                p-id="3963"
              ></path>
            </svg>
            <div class="mr">个人中心</div>
          </div>
          <div
            class="userleftbox cursor"
            :class="type == 2 ? 'type1' : ''"
            @click="seelect(2)"
          >
            <svg
              t="1649382334120"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="6970"
              width="14"
              height="14"
            >
              <path
                d="M793.6 96H230.4C173.8 96 128 141.8 128 198.4v627.2c0 56.6 45.8 102.4 102.4 102.4h563.2c56.6 0 102.4-45.8 102.4-102.4V198.4C896 141.8 850.2 96 793.6 96zM320 313.6h192c21.2 0 38.4 17.2 38.4 38.4s-17.2 38.4-38.4 38.4H320c-21.2 0-38.4-17.2-38.4-38.4s17.2-38.4 38.4-38.4z m320 396.8H320c-21.2 0-38.4-17.2-38.4-38.4s17.2-38.4 38.4-38.4h320c21.2 0 38.4 17.2 38.4 38.4s-17.2 38.4-38.4 38.4z m64-160H320c-21.2 0-38.4-17.2-38.4-38.4s17.2-38.4 38.4-38.4h384c21.2 0 38.4 17.2 38.4 38.4s-17.2 38.4-38.4 38.4z"
                :fill="type == 2 ? '#00bafd' : '#262626'"
                p-id="6971"
              ></path>
            </svg>
             <div class="mr">我的订单</div>
          </div>
          <div
            class="userleftbox cursor"
            :class="type == 3 ? 'type1' : ''"
            @click="seelect(3)"
          >
            <svg
              t="1649382443307"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5420"
              width="14"
              height="14"
            >
              <path
                d="M1013.318 283.028c-5.165-7.135-13.437-11.373-22.256-11.373h-764.063l-52.463-219.533c-2.956-12.366-14.006-21.082-26.714-21.082h-115.701c-15.168 0-27.465 12.285-27.465 27.465 0 15.182 12.295 27.465 27.465 27.465h94.028l168.638 705.673c-41.965 12.96-72.751 51.654-72.751 97.817 0 56.727 46.141 102.86 102.845 102.86 56.714 0 102.854-46.133 102.854-102.86 0-17.387-4.749-33.531-12.397-47.903h244.8c-7.65 14.373-12.397 30.516-12.397 47.903 0 56.727 46.141 102.86 102.854 102.86 56.707 0 102.845-46.133 102.845-102.86 0-56.701-46.141-102.833-102.845-102.833h-400.531l-12.252-51.257h518.265c11.847 0 22.362-7.591 26.077-18.857l134.986-408.785c2.769-8.369 1.347-17.569-3.822-24.703zM750.595 841.557c26.419 0 47.917 21.484 47.917 47.903s-21.498 47.93-47.917 47.93c-26.426 0-47.922-21.51-47.922-47.93-0.001-26.419 21.497-47.903 47.922-47.903zM372.809 889.461c0 26.419-21.498 47.93-47.922 47.93-26.419 0-47.917-21.51-47.917-47.93 0-26.419 21.498-47.903 47.917-47.903 26.426 0 47.922 21.484 47.922 47.903z"
                p-id="5421"
                :fill="type == 3 ? '#00bafd' : '#262626'"
              ></path>
            </svg>
             <div class="mr">我的寻舱</div>
          </div>
          <div
            class="userleftbox cursor"
            :class="type == 4 ? 'type1' : ''"
            @click="seelect(4)"
          >
            <svg
              t="1649382947363"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5844"
              width="14"
              height="14"
            >
              <path
                d="M950.9 0H72.8C32.6 0.1 0 32.8 0 73.1v877.7c0 40.3 32.6 73 72.9 73.1h878c40.4 0 73.1-32.7 73.1-73.1V73.1C1024 32.8 991.2 0 950.9 0zM193.7 339.9h290.9v-75.3H250.9v-51h233.7v-61.9h54.9v61.9h229v51h-229v75.3h276.8v34.5C803.7 428.5 786.4 481 766 532.8l-53.3-14.9c18.8-37.6 33.7-80 46.3-127H193.7v-51z m97.2 119.2l27.4-40c58.8 18.8 112.9 41.6 162.3 68.2l-29 43.1c-51.7-29.8-105.8-54.1-160.7-71.3z m123.9 116.8L386.5 619c-53.3-34.5-108.2-61.2-164.7-80.8l26.7-39.2c60.4 20.4 116.1 46.3 166.3 76.9zM839 682.5H557.5c-4.7 8.6-10.2 17.2-15.7 25.9 103.5 32.1 197.6 70.6 283.1 114.5l-31.4 49.4c-92.5-52.5-186.6-94.1-283.8-125.5-3.9 3.1-7.1 6.3-10.2 9.4-57.2 48.6-150.5 86.3-280.7 113.7L189 822.1c130.2-25.9 220.3-60.4 271.3-102.7 12.5-11.8 23.5-24.3 32.9-36.8H185v-54.1h337.2c9.4-25.1 14.1-52.5 15.7-82.3V421.4H592v124.7c-1.6 29.8-5.5 57.2-12.5 82.3H839v54.1z"
                p-id="5845"
                :fill="type == 4 ? '#00bafd' : '#262626'"
              ></path>
            </svg>
            <div class="mr">我的卖舱</div>
          </div>
        </div>
        <div class="userRight">
          <div v-show="type == 1">
            <div class="title">个人中心</div>
            <div class="boxInfo" style="margin-top: 30px">
              <span class="smallTitle">手机号:</span>{{ phone }}
            </div>
            <div class="boxInfo" style="margin-top: 11px">
              <span class="smallTitle">公司全称：</span>
              <span class="smallTitle2 smallTitles" v-show="!styleFlag"  style="color: #515a6e;letter-spacing: 1px;" >
                {{
                  userInfo.shopAddress
                    ? userInfo.shopAddress
                    : "暂无数据，请编辑"
                }}</span
              >
              <span class="editInfos smallTitles" v-show="styleFlag" >
                <el-input
                  :placeholder="
                    userInfo.shopAddress
                      ? userInfo.shopAddress
                      : '请输入公司全称'
                  "
                  v-model="userInfo.shopAddress"
                  @change="opedit('公司全称', userInfo.shopAddress)"
                  style="font-size: 12px;"
                >
                </el-input>
              </span>
            </div>
            <div class="boxInfo" style="margin-top: 20px">
              <span class="smallTitle">联系人：</span>
              <span
                class="smallTitle2 smallTitles"
                v-show="!styleFlag"
                style="color: #515a6e;letter-spacing: 1px;"
              >
                {{
                  userInfo.userName ? userInfo.userName : "暂无数据，请编辑"
                }}</span
              >
              <span class="editInfos" v-show="styleFlag">
                <el-input
                  :placeholder="
                    userInfo.userName ? userInfo.userName : '请输入联系人'
                  "
                  v-model="userInfo.userName"
                  style="font-size: 12px;"
                >
                </el-input>
              </span>
            </div>

            <div class="boxInfo" style="margin-top: 20px">
              <span class="smallTitle ">邮箱：</span>
              <span class="smallTitle2  smallTitles" v-show="!styleFlag" style="color: #515a6e;letter-spacing: 1px;">
                {{
                  userInfo.registerMail
                    ? userInfo.registerMail
                    : "暂无数据，请编辑"
                }}</span
              >
              <span class="editInfos " v-show="styleFlag">
                <el-input
                  :placeholder="
                    userInfo.registerMail ? userInfo.registerMail : '请输入邮箱'
                  "
                  v-model="userInfo.registerMail"
                  style="font-size: 12px;"
                >
                </el-input>
              </span>
            </div>
            <div class="boxInfos" style="margin-top: 20px">
              <div class="smallTitles">
                营业执照：
                <i v-if="userInfo.bizStatus == 'reject'" style="color: red"
                  >审核失败：拒绝原因 {{ userInfo.checkReason }}</i
                >
                <i v-if="userInfo.bizStatus == 'checked'">审核通过</i>
              </div>

              <div style="margin-top: 11px">
                <div
                  class="zhezhao" @click="zhezhao"
                  v-show="!userInfo.registerMail"
                ></div>
                <el-upload
                
                  class="avatar-uploader"
                  action="https://test.dosbooking.com:8700/system/uploadImg"
                  :show-file-list="false"
                  :on-success="handleImgSuccess"
                  :on-change="onChange"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="userInfo.registerCompanyCard"
                    :src="userInfo.registerCompanyCard"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>
            <div class="btns">
              <div @click="loginOut" class="loginOut">退出登陆</div>
              <div
                class="myEdit el-icon-edit"
                @click="editMyMsg"
                v-show="!styleFlag"
              >
                编辑
              </div>
              <div class="myEdit" @click="updateUser" v-show="styleFlag">
                保存
              </div>
            </div>
          </div>

          <div v-show="type == 2">
            <div class="title">我的订单</div>
            <div class="type2">
              <el-form
                class="demo-form-inline ordersearch"
                :inline="true"
                label-width="63px"
              >
                <el-form-item label="订单号">
                  <el-input
                    clearable
                    v-model="params.id"
                    placeholder="请输入订单号"
                    style="width: 194px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="起运港">
                  <el-select
                    style="width: 114px"
                    v-model="params.startSeaport"
                    filterable
                    clearable
                    :filter-method="getFreightPort"
                    placeholder="起运港"
                  >
                    <el-option
                      v-for="item in FreightPortList1"
                      :key="item.id"
                      :label="item.name"
                      :value="item.cname"
                    >
                      <span style="float: left">{{ item.name }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.cname }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="中转港">
                  <el-select
                    v-model="params.changeSeaport"
                    filterable
                    clearable
                    :filter-method="getFreightPort2"
                    placeholder="中转港"
                    style="width: 114px"
                  >
                    <el-option
                      v-for="item in FreightPortList2"
                      :key="item.id"
                      :label="item.name"
                      :value="item.cname"
                    >
                      <span style="float: left">{{ item.name }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.cname }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="目的港">
                  <el-select
                    v-model="params.targetSeaport"
                    filterable
                    clearable
                    :filter-method="getFreightPort2"
                    placeholder="中转港"
                    style="width: 114px"
                  >
                    <el-option
                      v-for="item in FreightPortList2"
                      :key="item.id"
                      :label="item.name"
                      :value="item.cname"
                    >
                      <span style="float: left">{{ item.name }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.cname }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="下单时间">
                  <el-date-picker
                    v-model="value1"
                    type="datetimerange"
                    class="edit"
                    start-placeholder="开始日期"
                  end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      placeholder="选择下单时间"
                  >
                  </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="开船日期" class="marLeft">
                  <el-date-picker
                    v-model="value2"
                    type="datetime"
                    class="edit"
                    value-format="yyyy-MM-dd"
                    placeholder="选择开船时间"
                  >
                  </el-date-picker>
                </el-form-item> -->
                <el-form-item label="订单状态" class="marLeft">
                  <el-select
                    v-model="params.weStatus"
                    filterable
                    clearable
                    :filter-method="getFreightPort2"
                    placeholder="订单状态"
                    style="width: 185px"
                  >
                    <el-option
                      v-for="item in statusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <span
                    @click="chongzhi"
                    class="dib"
                    style="
                      cursor: pointer;
                      color: #00bafd;
                      border-bottom: 1px solid #00bafd;
                      line-height: 15px;
                    "
                    >重置</span
                  >
                  <span @click="searchOrder4App" class="search dib">搜索</span>
                </el-form-item>
              </el-form>
            </div>
            <div>
              <div class="orderTop">
                <div style="margin-left: 30px;">订单状态</div>
                <div style="">开船信息</div>
                <div style="">附加费</div>
                <div style="">总计</div>
                <div style="margin-right: 30px">操作</div>
              </div>

              <div class="order" v-for="item in orderList" :key="item.id">
                <div class="orderbox">
                  <!-- 为空 -->
                  <img
                    v-if="!orderList.length"
                    src="./nodata.png"
                    alt=""
                    class="noImg"
                  />
                  <div class="orderStatus" style="width:100px">
                    {{ getStatus(item.weStatus) }}
                  </div>
                  <div class="orderTime">
                    <div class="time">
                      {{item.weStartSeaport}} -- {{item.weTargetSeaport}}
                     
                    </div>
                    <div class="time">
                      截文件：
                      <div>
                        {{ item.weCloseFileTime.substr(0, 10) }}
                      </div>
                    </div>

                    <div class="time">
                      截放行：
                      <div>
                        {{ item.weClosePassTime.substr(0, 10) }}
                      </div>
                    </div>

                    <div class="time">
                      发船：
                      <div>
                        {{ item.weSailTime.substr(0, 10) }}
                      </div>
                    </div>
                  </div>
                  <div class="feiyong">
                   
                    <div
                      v-show="item.surchargeAmountDetail != 0 || item.surchargeAmountDetail  "
                    >
                      <el-tooltip placement="bottom">
                        <div slot="content">
                          <div
                            v-for="(item, index) in item.surchargeAmountDetail"
                            style="width: 200px"
                            :key="index"
                            class="fujiafei"
                          >
                            <div style="width: 50px">{{ item.feeName }}</div>
                            <div style="margin-left: 20px; width: 150px">
                              金额：{{ item.fee }}({{ item.feeType }})
                            </div>
                          </div>
                        </div>
                        
                        <div class="link" v-show="item.surchargeAmountDetail.length != 0">
                          <div class="cursor" v-show="item.cnyTotal != 0 || !item.cnyTotal">
                            {{ item.cnyTotal }}(CNY)
                          </div>
                          <div
                            style="margin-top: 5px"
                            class="cursor"
                            v-show="item.usdTotal != 0 || !item.usdTotal"
                          >
                            {{ item.usdTotal }}(USD)
                          </div>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="orderTotal">
                    <div>
                      <div class="txts">价格(USD):  {{ item.weCabinetAmount * item.weNum}}</div>
                    
                    </div>
                    <!-- <div>
                      <div class="txts">服务费(USD):{{ item.wxServiceFee }}</div>
                      
                    </div> -->
                    <div>
                      <div class="txts" v-show="item.depositEnable == 1">履约保证金（USD）:{{ item.weDepositAmount }}</div>
                      
                    </div>
                    <!-- <div
                      style="margin-top: 14px"
                      v-show="item.depositEnable == 1"
                    >
                      履约保证金（USD）
                    </div>
                    <div
                      style="font-weight: 500; margin-top: 5px"
                      v-show="item.depositEnable == 1"
                    >
                      {{ item.depositAmount }}
                    </div> -->
                  </div>
                  <div class="ordercaozuo">


 
                    <div @click="orderInfo(item.id)">订单详情</div>
                    <!-- <div
                      class="cannelOrder"
                      @click="cannelOrder(item.id)"
                      v-show="item.weStatus == '1'"
                    >
                      取消订单
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="paginations">
                <el-pagination
                  background
                  :page-size="3"
                  @current-change="sizeChange"
                  layout="prev, pager, next"
                  :total="totalCount"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div v-if="type == 3">
            <div class="title">我的寻舱</div>
            <seek v-if="type == 3"></seek>
          </div>
          <div v-if="type == 4">
            <div class="title">我的卖舱</div>
            <sell v-if="type == 4"></sell>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="title"
      width="30%"
      :visible.sync="dialogVisible"
      :before-close="handleCloseUser"
    >
      <span>
        <el-input v-model="value" class="edit"></el-input>
      </span>
      <span slot="footer" class="dialog-footer1">
        <el-button size="small" type="primary" @click="updateUser"
          >确 定</el-button
        >
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="上传补料信息"
      :visible.sync="dialogVisiblebl"
      width="40%"
      :before-close="handleClosebk"
    >
      <!-- <span> -->
      <div>
        <span
          class="link"
          @click="
            downLoad(
              'https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/163971342064197.xlsx'
            )
          "
        >
          下载补料模版
        </span>
      </div>
      <div class="flex upload">
        <el-upload
          class="upload-demo"
          drag
          action="https://test.dosbooking.com:8700/system/uploadImg"
          :on-success="handleSuccess"
          limit="1"
          :on-exceed="onExceed"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div class="fileListt">
          <div v-for="(item, index) in fileList" :key="item.id" class="filebox">
            <div style="margin-left: 34px">
              {{ item.name }}
            </div>
            <div
              style="margin-right: 10px; width: 24px; height: 24px"
              class="closefile"
              @click="deltfile(index)"
            >
              <img
                src="../index/C.png"
                alt=""
                srcset=""
                style="width: 24px; height: 24px"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <el-input v-model="value"></el-input> -->
      <!-- </span> -->
      <span slot="footer" class="dialog-footer1">
        <el-button size="small" type="primary" @click="updateBl"
          >确 定</el-button
        >
        <el-button @click="dialogVisiblebl = false" size="small"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="上传VGM信息"
      :visible.sync="dialogVisibleVGM"
      width="40%"
      :before-close="handleCloseVgm"
    >
      <!-- <span> -->
      <div>
        <span
          class="link"
          @click="
            downLoad(
              'https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/163971335416114.xlsx'
            )
          "
        >
          下载VGM模版
          <!-- https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/163947835650978.xls -->
        </span>
      </div>
      <div class="flex upload">
        <el-upload
          class="upload-demo"
          drag
          action="https://test.dosbooking.com:8700/system/uploadImg"
          :on-success="handleSuccess"
          limit="1"
          :on-exceed="onExceed"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div class="fileListt">
          <div v-for="(item, index) in fileList" :key="item.id" class="filebox">
            <div style="margin-left: 34px">
              {{ item.name }}
            </div>
            <div
              style="margin-right: 10px; width: 24px; height: 24px"
              class="closefile"
              @click="deltfile(index)"
            >
              <img
                src="../index/C.png"
                alt=""
                srcset=""
                style="width: 24px; height: 24px"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <el-input v-model="value"></el-input> -->
      <!-- </span> -->
      <span slot="footer" class="dialog-footer1">
        <el-button size="small" type="primary" @click="updateVGM"
          >确 定</el-button
        >
        <el-button @click="dialogVisibleVGM = false" size="small"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="请选择您对本次订舱满意程度："
      :visible.sync="dialogVisibleeve"
      width="660px"
    >
      <div>
        <div class="flex pongfen">
          <div>总体评分:</div>
          <div class="rate" style="margin-left: 90px">
            <van-rate
              v-model="evaluateOrderParams.starNum"
              :size="25"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
            <i>{{ rate }}.0</i>
          </div>
        </div>
        <div class="pingfeng">
          <div>填写评价：</div>
          <div>匿名<el-switch v-model="anonymousFlag"> </el-switch></div>
        </div>
        <el-input
          type="textarea"
          style="margin-top: 10px; height: 140px"
          placeholder="请输入评价"
          v-model="evaluateOrderParams.evaluateTxt"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer1">
        <el-button
          size="small"
          type="primary"
          @click="addOrderEvaluation"
          :loading="loevaluayion"
          >确 定</el-button
        >
        <el-button @click="dialogVisibleeve = false" size="small"
          >取 消</el-button
        >
      </span>
      <!-- orderEvaluationManager/addOrderEvaluation -->
    </el-dialog>
    <footer-bottom></footer-bottom>
      <sellingPort ref="sellingPort"></sellingPort>  
        <seekPort ref="seekPort"></seekPort> 
  </div>
</template>
<script>
import HeaderTop from "../common/header.vue";
import footerBottom from "../common/footer.vue";
import seek from "./seek.vue";
import sell from "./sell.vue";
import seekPort from "../common/seekPort.vue";
import sellingPort from '../common/sellingPort.vue'
export default {
  components: { HeaderTop, footerBottom, seek, sell ,seekPort,sellingPort},
  data() {
    return {
      styleFlag: false,
      options: [],
      loevaluayion: false,
      anonymousFlag: false,
      value: "",
      totalCount: 0,
      dialogVisiblebl: false,
      dialogVisibleeve: false,
      type: "",
      phone: "",
      dialogVisible: false,
      value: "",
      title: "",
      userInfo: {},
      company: "",
      fileList: [],
      orderList: [],
      value1: [],
      value2: [],
      imgfileList: "",
      dialogVisibleVGM: false,
      title: "",
      rate: "5",
      evaluateOrderParams: {
        //订单id
        orderId: "",
        //用户id
        userId: "",
        //供应商id
        supplierId: "",
        //评价星数
        starNum: 5,
        //评价内容
        evaluateTxt: "",
        //是否匿名(0:不匿名，1:匿名)
        anonymousFlag: "",
      },
      params: {
        pageSize: 3,
        pageIndex: 1,
        id: "",
        startSeaport: "",
        changeSeaport: "",
        targetSeaport: "",
        weStatus: "",
      },
      statusList: [
        // 2.审核拒绝、3.已审核、4.取消、5.重新申请、6.付款
         {label: '已订舱',name: '1'},
                    {label: '已付押金',name: '2'},
                    {label: '已下SO',name: '3'},
                    {label: '已提交补料',name: '4'},
                    {label: '已提交VGM',name: '5'},
                    {label: '已发船',name: '6'},
                    {label: '已出账待付款',name: '7'},
                    {label: '交易成功',name: '8'},
                    {label: '已取消',name: '9'}
      ],
      orderId: "",
      imageUrl: "",
    };
  },
  created() {
    this.searchOrder4App();

    // if(this.$router.query == '1'){
    //     thi
    // }
  },
  watch: {
    $route(to, from) {
      if (to.query.type == "1") {
        this.type = this.$route.query.type;
        this.routername = "user";
      }
      if (to.query.type == "2") {
        this.routername = "order";
        this.type = this.$route.query.type;
      }
      if (to.query.type == "3") {
        this.routername = "order";
        this.type = this.$route.query.type;
      }
      if (to.query.type == "4") {
        this.routername = "order";
        this.type = this.$route.query.type;
      }

     
    },
  },
  mounted() {
    this.getFreightPort();
    this.getFreightPort1();
    this.getFreightPort2();
    this.getFreightPort3();
    let token = localStorage.getItem("token");
    if (!token || token == "null" || token == "" || token == "undefined") {
      // next('/login')
    } else {
      this.token = token;
      let userInfo = localStorage.getItem("userInfo");
      this.userInfo = JSON.parse(userInfo).user;
      // debugger
      // this.
      this.phone = JSON.parse(userInfo).user.phone;
      // this.
    }
    if(this.$route.query.edit == 1){
      // alert('1')
      this.styleFlag = true;
    }
    this.type = this.$route.query.type;
  },
  methods: {
    fabucangwei(){
             let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                  this.$refs.headerTop.login()
            } else {
            //    this.zindeshow()
                this.$refs.sellingPort.errshowoLoad()
                //   this.$emit('updataSelect')
                // this.$router.push('/user?type=2')
            }
            //  _MEIQIA('metadata', {
            //     comment:  '客服点击发布舱位发起对话'
            // })
            //  _MEIQIA('showPanel');  
        },
         hangxiangShow(){
           
             let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                 this.$refs.headerTop.login()
                
            } else {
                // this.$router.push('/seek')
                // this.dialogVisible = true
                // this.zIndex = true
                 this.$refs.seekPort.showDialogVisible()
            }
          
        },
    // 个人中心-编辑
    editMyMsg() {
      this.styleFlag = true;
    },
    // 确认编辑
    ensureEdit() {
      this.styleFlag = false;
    },
    getFreightPort(val) {
      this.params.startSeaport = val;
      this.$post("/system/getFreightPort", { name: val }).then((res) => {
        this.FreightPortList1 = res.data.list;
        // this.FreightPortList2 = res.data.list
        // this.FreightPortList3 = res.data.list
      });
    },
    getFreightPort1(val) {
      this.params.targetSeaport = val;
      this.$post("/system/getFreightPort", { type: "target", name: val }).then(
        (res) => {
          this.FreightPortList3 = res.data.list;
          // this.FreightPortList2 = res.data.list
          // this.FreightPortList3 = res.data.list
        }
      );
    },
    getFreightPort2(val) {
      this.params.changeSeaport = val;
      this.$post("/system/getFreightPort", {
        type: "transfer",
        name: val,
      }).then((res) => {
        this.FreightPortList2 = res.data.list;
        // this.FreightPortList2 = res.data.list
        // this.FreightPortList3 = res.data.list
      });
    },
    getFreightPort3(val) {
      this.$post("/system/getFreightPort", { name: val }).then((res) => {
        this.FreightPortList4 = res.data.list;
        // this.FreightPortList2 = res.data.list
        // this.FreightPortList3 = res.data.list
      });
    },
    // zhezhao() {
    zhezhao(){
            // alert('11')
            this.$message.error('请先填写邮箱')
        },
    // },
    chongzhi() {
      this.params = {
        pageSize: 5,
        pageIndex: 1,
        id: "",
        startSeaport: "",
        changeSeaport: "",
        targetSeaport: "",
        status: "",
      };
      this.value1 = [];
      this.value2 = [];
      this.searchOrder4App();
    },
    handleImgSuccess(res, file, fileList) {
      if (res.code == "200") {
        // this.fileList = fileList
        this.imageUrl = res.data;
        console.log(this.imgfileList);
        this.$Notice.success({
          title: "上传成功，请等待小二审核",
        });
        let params = this.userInfo
        params.registerCompanyCard = this.imageUrl
        params.userId = this.userInfo.userId
        // let params = {
        //   userId: this.userInfo.userId,
        //   registerCompanyCard: this.imageUrl,
        //   registerMail:this.userInfo.registerMail
        // };
        this.$post("/user/updateUser4App", params).then((res) => {
          if (res.code == "200") {
            this.getUserInfo();
            this.styleFlag = false
            // this.dialogVisible = false
          }
        });
        // this.initData()
      }
      this.fullscreenLoading = false;
      // this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
      //     loadingInstance.close();
      // });
    },
    handleSuccess(res, file, fileList) {
      if (res.code == "200") {
        this.fileList = fileList;
        this.imageUrl = res.data;
        console.log(this.imgfileList);
        this.$Notice.success({
          title: "上传成功",
        });
        // this.initData()
      }
      this.fullscreenLoading = false;
      // this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
      //     loadingInstance.close();
      // });
    },
    shangchuan(id) {
      this.$router.push("/payAmount?orderId=" + id);
    },
    handleCloseVgm() {
      this.dialogVisibleVGM = false;
    },
    handleClosebk() {
      this.dialogVisiblebl = false;
    },
    evaluateOrder(id) {
      this.dialogVisibleeve = true;
      // this.evaluateOrderParams = id
      this.evaluateOrderParams.orderId = id.id;
      this.evaluateOrderParams.userId = localStorage.getItem("empId");
      this.evaluateOrderParams.supplierId = id.supplierCompanyCode;
      // this.evaluateOrderParams.orderId = id
    },
    downLoad(url) {
      // debugger
      const el = document.createElement("a");
      el.href = url;
      document.body.appendChild(el);
      el.click();

      // window.location.href = url
    },
    onExceed() {
      this.$message.error("当前文件只能上传一个");
    },
    onChange() {
      debugger;
    },
    tjbl(id) {
      this.orderId = id;
      this.dialogVisiblebl = true;
      this.fileList = [];
      this.imgfileList = "";
    },
    tjVgm(id) {
      this.orderId = id;
      this.dialogVisibleVGM = true;
      this.fileList = [];
      this.imgfileList = "";
    },
    addOrderEvaluation() {
      this.loevaluayion = true;
      // this.evaluateOrderParams.supplyBizUserId = .
      if (this.evaluateOrderParams.anonymousFlag) {
        this.evaluateOrderParams.anonymousFlag = 0;
      } else {
        this.evaluateOrderParams.anonymousFlag = 1;
      }
      this.$post(
        "/orderEvaluationManager/addOrderEvaluation",
        this.evaluateOrderParams
      ).then((res) => {
        if (res.code == "200") {
          this.$message.success("评价成功");
        }
        this.loevaluayion = false;
      });
    },
    updateBl() {
      if (this.imgfileList == "") {
        this.$message.error("请上传文件");
        return false;
      }
      let params = {
        orderId: this.orderId,
        file: this.imgfileList,
      };
      this.$post("/freight/uploadBl", params).then((res) => {
        if (res.code == "200") {
          this.dialogVisiblebl = false;
          this.$message.success("提交成功");
          this.searchOrder4App();
        }
      });
    },
    updateVGM() {
      if (this.imgfileList == "") {
        this.$message.error("请上传文件");
        return false;
      }
      let params = {
        orderId: this.orderId,
        file: this.imgfileList,
      };
      this.$post("/freight/uploadVgm", params).then((res) => {
        if (res.code == "200") {
          this.dialogVisibleVGM = false;
          this.$message.success("提交成功");
          this.searchOrder4App();
        }
      });
    },
    deltfile(index) {
      this.fileList.splice(index, 1);
      this.imgfileList.splice(index, 1);
    },
    handleSuccess(res, file, fileList) {
      if (res.code == "200") {
        this.fileList = fileList;
        this.imgfileList = res.data;
        // this.imgfileList.push(res.data)
        console.log(this.imgfileList);
        this.$Notice.success({
          title: "上传成功",
        });
        // this.initData()
      }
      this.fullscreenLoading = false;
    },
    sizeChange(val) {
      this.params.pageIndex = val;
      this.searchOrder4App();
    },
    getStatus(status) {
      for (var i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].name == status) {
          return this.statusList[i].label;
        }
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/bmp" ||
        file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("请上传图片");
      }

      return isJPG;
    },
    updataSelect() {
      // console.log(this.$route.name)
      if (this.$route.name == "user") {
        this.type = this.$route.query.type;
      }
    },
    searchOrder4App() {
      // debugger
      if (this.value1 != null && this.value1.length != 0) {
        this.params.sailTimeStart = this.value1[0] + " 00:00:00";
        this.params.sailTimeEnd = this.value1[1] + " 23:59:59";
      } else {
        this.params.sailTimeStart = "";
        this.params.sailTimeEnd = "";
      }
      if (this.value2 != null && this.value2.length != 0) {
        this.params.startTime = this.value2[0] + " 00:00:00";
        this.params.endTime = this.value2[1] + " 23:59:59";
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
      
      this.params.userId= localStorage.getItem('empId')
      this.$post("/workOrder/myWeOrders", this.params).then((res) => {
        res.data.list.forEach((ele) => {
          if (ele.weSurchargeAmountDetail) {
            ele.surchargeAmountDetail = JSON.parse(ele.weSurchargeAmountDetail);
            console.log(ele.surchargeAmountDetail);
            let usdTotal = 0;
            let cnyTotal = 0;
            ele.surchargeAmountDetail.forEach((ele1) => {
              console.log(ele1);
              if (ele1.feeType == "CNY") {
                cnyTotal += ele1.fee;
                // debugger
              }
              if (ele1.feeType == "USD") {
                usdTotal += ele1.fee;
              }
              // debugger
              ele.usdTotal = usdTotal;
              // // debugger
              ele.cnyTotal = cnyTotal;
            });
          }
        });
        this.orderList = res.data.list;
        console.log("1111", this.orderList);
        this.totalCount = Number(res.totalCount);
        // debugger
      });
    },
    seelect(type) {
      this.type = type;
    },
    loginOut() {
      localStorage.clear();
      this.$toast.success("退出登录成功");
      this.$router.push("/index");
    },
    opedit(type, val) {
      this.title = type;
      this.value = val;
      // this.dialogVisible = true;
      console.log(this.userInfo);
    },
    cannelOrder(id) {
      this.$confirm("是否取消当前订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            orderId: id,
          };
          this.$post("/freight/cancelOrder", params).then((res) => {
            if (res.code == "200") {
              this.searchOrder4App();
            }
          });
        })
        .catch(() => {});
    },
    orderInfo(id) {
      this.$router.push("/orderInfo?orderId=" + id);
    },
    getUserInfo(userId) {
      this.$post("/user/getUserInfo", { userId: this.userInfo.userId }).then(
        (res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          // localStorage.setItem("token", res.data.token);
          localStorage.setItem("empId", res.data.userId);
          localStorage.setItem("username", res.data.username);
          localStorage.setItem("fillName", res.data.fillName);
          localStorage.setItem("positionName", res.data.positionName);
          localStorage.setItem("multistageDetp", res.data.multistageDetp);
          let userInfo = localStorage.getItem("userInfo");
          this.userInfo = JSON.parse(userInfo).user;
          // debugger
          // this.
          this.phone = JSON.parse(userInfo).user.phone;
        }
      );
    },
    handleCloseUser() {
      this.dialogVisible = false;
    },
    updateUser() {
      console.log(this.userInfo);
      let params = this.userInfo;
      // let params = {
      //   userId: this.userInfo.userId,
      // };
      // if (this.title == "公司名称") {
      //   params.shopAddress = this.value;
      // }
      // if (this.title == "联系方式") {
      //   params.emergencyContactPhone = this.value;
      // }
      // if (this.title == "联系人") {
      //   params.userName = this.value;
      // }
      // if (this.title == "邮箱") {
      //   params.registerMail = this.value;
      // }
      this.$post("/user/updateUser4App", params).then((res) => {
        if (res.code == "200") {
          // this.dialogVisible = false;
          // this.$toast.success("修改成功");
          this.getUserInfo();
          // localStorage.setItem('userInfo',JSON.stringify(res.data))
          // localStorage.setItem("token", res.data.userToken);
          // localStorage.setItem("empId", res.data.userId);
          // localStorage.setItem("username", res.data.username);
          // localStorage.setItem("fillName", res.data.fillName);
          // localStorage.setItem("positionName", res.data.positionName);
          // localStorage.setItem("multistageDetp", res.data.multistageDetp);
          // let userInfo = localStorage.getItem('userInfo')
          // this.userInfo = JSON.parse(userInfo).user
          this.styleFlag = false;
          this.phone = res.data.phone;
          console.log(res, "222222222222");
          // this.phone = JSON.parse(userInfo).user.phone
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bigImage {
  height: 600px;
  position: relative;
  margin-bottom: 100px;
  z-index: -1;
}
.searchBox {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -55px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 0px;
  margin: 0 auto;
}
.searchimte {
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  border: 4px solid #ffffff;
  width: 1120px;
  margin: 0 auto;
  border-right: none;
  background: #f9f9f9;
}
// .search {
//   height: 92px;
//   background: #fff;
//   display: flex;

//   align-items: center;
//   justify-content: space-between;
// }
.searchImage {
  width: 100px;
  height: 100px;
  background: #1677ff;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;
  // border-left: ;
}
.searchImage img {
  width: 36px;
  height: 36px;
}
.searchInput {
  margin-top: 8px;
  margin-left: 45px;
  height: 48px;
}
.el-select {
  width: 162px;
}

.kefu {
  width: 50px;
  height: 22px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #1677ff;
  color: #1677ff;
}
.yuding {
  background: #1677ff;
  color: #fff;
}
.table {
  width: 1120px;
  margin: 0 auto;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  background: #ffffff;
  border: 4px solid #ffffff;
  margin-bottom: 100px;
}
.tableData {
  box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.2);
  border-radius: 4px;
  width: 1080px;
  margin: 0 auto;
}
.main {
  background: -webkit-gradient(linear, 0 0, 0 50%, from(#73daff), to(#fff));
  padding: 70px 0;
}
.userBox {
  width: 1188px;
  margin: 0px auto;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  border-radius: 8px;
}
.userLeft {
  position: relative;
  width: 163px;
  height: 578px;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: -16px 4px 40px 1px rgba(0, 186, 253, 0.2);
  opacity: 1;
  background: linear-gradient(
    180deg,
    #baebfd 10%,
    rgba(255, 255, 255, 0.5) 40%
  );
}
.userRight {
  position: relative;
  // z-index: 1;
  width: 100%;
  box-shadow: 0px 4px 40px 1px rgba(0, 186, 253, 0.2);
  background-color: #fff;
  border-radius: 8px;
  margin-left: -10px;
}
.userPic {
  position: absolute;
  top: -50px;
  background-color: #fff;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background-color: #baebfd;
}
.userleftbox {
  width: 163px;
  height: 66px;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mr{
  margin-left: 6px;
}
.type1 {
  background-color: #fff;
  color: #00bafd;
  font-size: 12px;
  font-family: Roboto-Regular, Roboto;
  box-shadow: -16px 4px 40px 1px rgba(0, 186, 253, 0.2);
}
.type1 :hover {
  background-color: #fff;
}
.title {
  font-weight: 400;
  color: #464646;
  font-size: 18px;
  margin-top: 12px;
  margin-left: 25px;
  width: 80px;
  line-height: 30px;
}
.boxInfo {
  height: 30px;
  // width: 250px;
  line-height: 30px;
  margin-left: 61px;
  margin-bottom: 11px;
}
.boxInfos {
  line-height: 30px;
  margin-left: 61px;
  padding-bottom: 11px;
  box-sizing: border-box;
  position: relative;
}
.smallTitle {
  display: inline-block;
  font-size: 12px;
  font-family: Roboto-Regular, Roboto;
  color: #acacac;
  line-height: 22px;
  letter-spacing: 5px;
  height: 30px;
  width: 85px;
}
.smallTitles {
  display: inline-block;
  font-size: 12px;
  font-family: Roboto-Regular, Roboto;
  color: #acacac;
  line-height: 22px;
  letter-spacing: 5px;
  height: 30px;
}
.info {
  display: inline-block;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.loginOut {
  width: 58px;
  height: 20px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  color: #00bafd;
  border-bottom: 1px solid #00bafd;
}
::v-deep .el-dialog__header {
  font-weight: 500;
}
::v-deep .edit .el-input__inner {
  width: 194px;
}
.ordersearch {
  margin-left: 20px;
  margin-top: 20px;
  ::v-deep .el-form-item__label {
    font-size: 12px;
    color: #464646;
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-date-editor .el-range-input {
    font-size: 12px;
  }
  ::v-deep .el-icon-date:before {
    position: absolute;
    top: -4px;
  }
  ::v-deep .el-date-editor .el-range-separator {
    position: absolute;
    top: -6px;
    left: 150px;
    color: #dadada;
  }
  ::v-deep .el-form-item {
    margin-bottom: 8px;
  }
  ::v-deep .el-form-item__content {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 240px;
  }
  ::v-deep .el-form--inline .el-form-item {
    margin-right: 0;
  }
}
::v-deep .edit .el-date-editor {
  width: 300px !important;
}
::v-deep .el-dialog__footer {
  width: 100%;
  text-align: center;
}
.titlemy {
  margin-left: 20px;
  margin-top: 40px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.orderTop {
  margin-top: 24px;
  width: 957px;
  height: 29px;
  line-height: 29px;
  font-size: 12px;
  color: #464646;
  background: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0px -1px 0px 0px #eeeeee;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.order {
  margin-left: 20px;
}
.orderbox {
  width: 957px;
  padding: 20px 0;
  line-height: 1.6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #464646;
  border-bottom: 1px solid #ebeef5;
  .orderTime {
    // padding-right: 50px;
    width: 120px;
  }
  .time {
    display: flex;
    justify-content: right;
    text-align: right;
  }
  .orderStatus {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 22px;
    padding-left: 26px;
  }
  .feiyong {
    display: flex;
    justify-content: center;
    font-weight: 500;
    color: #666666;
    width: 50px;
  }
  .orderTotal {
    font-weight: 500;
    width: 120px;
  }
  .txts {
    text-align: right;
    display: inline-block;
    min-width: 120px;
    // max-width: 200px;
  }
  .ordercaozuo {
    font-weight: 500;
    padding-right: 18px;
  }
}
.type2 {
  ::v-deep .el-input__inner {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  ::v-deep .el-input__icon {
    height: 24px;
    line-height: 24px;
  }
  ::v-deep .el-input__inner:focus {
    color: #00bafd;
  }
}
.ordercaozuo div {
  cursor: pointer;
  border-bottom: 1px solid #00bafd;
  color: #00bafd;
  line-height: 15px;
}
.ordercaozuo div:not(:first-child) {
  margin-top: 10px;
}
.cannelOrder {
  color: #e02020;
}
.upload {
  margin-top: 32px;
}
::v-deep .el-upload-dragger {
  width: 320px;
  height: 140px;
}
.fileListt {
  margin-left: 20px;
}
.fileListt div:nth-child(1) {
  margin-top: 0 !important;
}
.filebox {
  width: 272px;
  height: 30px;
  background: #f5f5f5;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.pongfen {
  display: flex;
  align-items: center;
}
.pingfeng {
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
}
.rate {
  display: flex;
  align-items: center;
}
.rate i {
  margin-left: 15px;
  font-size: 20px;
  color: #cccccc;
}
::v-deep .van-icon {
  font-size: 40px !important;
}
::v-deep .el-textarea__inner {
  height: 100%;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #00bafd;
}
.paginations {
  height: 32px;
  text-align: center;
  position: absolute;
  bottom: 30px;
  width: 100%;
}
::v-deep .quedingdiv {
  background: #00bafd;
  width: 53px;
  height: 26px;
  line-height: 26px;
  border-radius: 5px;
}
::v-deep .page .el-input__inner {
  width: 36px !important;
  height: 26px;
  line-height: 26px;
  border-radius: 5px;
}
.fujiafei {
  display: flex;
  justify-content: space-between;
}
.link {
  cursor: pointer;
  color: #2ba3ff;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
::v-deep .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.zhezhao {
  width: 180px;
  cursor: pointer;
  height: 100%;
  // background: red;
  position: absolute;
  // top: 110px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}
.el-range-editor {
  margin-left: 0 !important;
}
.pic1 {
  width: 62px;
  margin-top: 6px;
  margin-left: 5px;
  font-size: 36px;
  color: #fff;
  background-color: #00bafd;
  border-radius: 50%;
  text-align: center;
  line-height: 62px;
}
.smallTitle2 {
  display: inline-block;
  line-height: 30px;
  font-size: 12px;
  color: #464646;
}
.myEdit {
  width: 103px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  background-color: #00bafd;
  font-size: 14px;
  color: #fff;
  margin-left: 13px;
  cursor: pointer;
  border-radius: 5px;
}
.btns {
  position: absolute;
  width: 100%;
  padding: 0;
  bottom: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 57px;
}
.editInfos {
  display: inline-block;
  height: 30px;
}
::v-deep .editInfos .el-input__inner {
  height: 30px;
  line-height: 30px;
}
::v-deep .editInfos .el-input__inner:focus {
  color: #00bafd;
}
.marLeft {
  margin-left: 21px;
}
.search {
  background-color: #00bafd;
  width: 82px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
}
.dib {
  display: inline-block;
}
::v-deep .el-table::before {
  z-index: -1 !important;
}
.noImg {
  position: absolute;
  width: 260px;
  height: 190px;
  top: 25%;
  left: 32%;
}
// .registerMailUpload{
//   background: red;
//   height: 200px;
// }
</style>
