<template>
  <div>
    <div class="userBox">
      <el-table
        :data="tableData"
        style="
          height: 400px;
          width: 957spx;
          font-size: 12px;
          color: #464646;
          padding-bottom: 40px;
        "
      >
        <el-table-column prop="date" type="index" label="序号" align="center">
        </el-table-column>
        <el-table-column
          prop="startSeaport"
          label="起运港"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="targetSeaport" label="目的港" align="center">
        </el-table-column>
        <el-table-column prop="shipCompanyName" label="船司" align="center">
        </el-table-column>
        <el-table-column
          prop="cabinetPredict"
          label="价格预算(usd)"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="closeOrderTime" label="截单时间" align="center">
            <template slot-scope="scope">
              <div>
                  {{scope.row.closeOrderTime ? (scope.row.closeOrderTime).substr(0,10) : ''}}
              </div>
            </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop="date" label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <div @click="xunwen(scope.row)" class="contectPer">联系客服</div>
               <div @click="gengduo(scope.row)" class="contectPer" style="margin-top:5px">更多信息</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <img v-if="!tableData.length" src="./nodata.png" alt="" class="noImg"/>

      <div class="btnXun">
        <div @click="seekShow" class="xuncang">我要卖舱</div>
      </div>

      <div class="pagination">
        <el-pagination
          class="page"
          background
          @current-change="sizeChange"
          layout="prev, pager, next,jumper"
          :total="total"
          :page-size="params.pageSize"
        >
        </el-pagination>
        <div class="quedingdiv" @click="getData">确定</div>
      </div>
    </div>
        <el-dialog
      title="卖舱详情"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <h2>寻舱编号：{{info.id}}</h2>
      <h2>卖舱信息：</h2>
      <div>
         <el-row class="mb-2">
            <el-col :span="12">
              起运港：{{info.startSeaport}}
            </el-col>
            <el-col :span="12">
               中转港：{{info.changeSeaport}}
            </el-col>
         </el-row>
          <el-row class="mb-2">
            <el-col :span="12">
              目的港：{{info.targetSeaport}}
            </el-col>
            <el-col :span="12">
               价格预算：{{info.sailTimeStart ? (info.sailTimeStart).substr(0,10) :'' }}
            </el-col>
         </el-row>
          <el-row class="mb-2">
            <el-col :span="12">
              截单时间时间：{{info.goodTime ? (info.goodTime).substr(0,10) : ''}}
            </el-col>
            <el-col :span="12">
               塞港费：{{info.portCharge}}
            </el-col>
         </el-row>
          <el-row class="mb-2">
            <el-col :span="12">
              押金：{{info.deposit}}
            </el-col>
            <el-col :span="12">
               船司：{{info.shipCompanyName}}
            </el-col>
         </el-row>
         <el-row class="mb-2">
            <el-col :span="12">
              船司：{{info.shipCompanyName}}
            </el-col>
            <el-col :span="12">
                额外费用：{{info.productName}}
            </el-col>
         </el-row>
          <el-row class="mb-2">
            <el-col :span="12">
               其他备注：
            </el-col>
            <el-col :span="12">
              装货时间：{{info.shipmentTimeStart ? (info.shipmentTimeStart).substr(0,10) :''}}
            </el-col>
         </el-row>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
    
    </span>
  </el-dialog>
    <sellingPort ref="sellingPort" @dataget="getData"></sellingPort>
  </div>
</template>

<script>
import HeaderTop from "../common/header.vue";
import footerBottom from "../common/footer.vue";
import sellingPort from "../common/sellingPort.vue";
export default {
  components: { HeaderTop, footerBottom, sellingPort },
  data() {
    return {
      total: 0,
      tableData: [],
       dialogVisible:false,
       info:{},
      params: {
        pageSize: "5",
        pageIndex: 1,
        orderType: 2,
      },
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    (function (a, b, c, d, e, j, s) {
      a[d] =
        a[d] ||
        function () {
          (a[d].a = a[d].a || []).push(arguments);
        };
      (j = b.createElement(c)), (s = b.getElementsByTagName(c)[0]);
      j.async = true;
      j.charset = "UTF-8";
      j.src = "https://static.meiqia.com/widget/loader.js";
      s.parentNode.insertBefore(j, s);
    })(window, document, "script", "_MEIQIA");
    _MEIQIA("entId", "3b5f06f295f4877f71e2e3b515160807");
    _MEIQIA("withoutBtn");
    _MEIQIA("hidePanel");
  },
  methods: {
     gengduo(val){
      // this.info = val
       this.$get('/workOrder/detail',{id:val.id}).then(res =>{
          // res.data.startSeaport = JSON.parse(res.data.startSeaport)
          this.info = res.data
      })
      this.dialogVisible = true
    },
    handleClose(){
      this.dialogVisible = false
    },
    seekShow() {
      let token = localStorage.getItem("token");
      if (!token || token == "null" || token == "" || token == "undefined") {
        // next('/login')
        this.token = "";
        this.$router.push("/login");
      } else {
        this.$refs.sellingPort.errshowoLoad();
        //   this.$emit('updataSelect')
        // this.$router.push('/user?type=2')
      }
    },
    sizeChange(num) {
      this.params.pageIndex = num;
      this.getData();
    },
    getData() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // let userId = userInfo.userId
      this.params.userId = userInfo.userId;
      this.$post("/workOrder/myOrders", this.params).then((res) => {
        console.log(res.data.list, "88888888888");
        this.tableData = res.data.list;
        this.total = res.totalCount;
      });
    },
    xunwen(row) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      _MEIQIA("metadata", {
        contact:
          "姓名：" +
          userInfo.user.userName +
          "公司名称：" +
          userInfo.user.shopAddress,
        email: userInfo.user.registerMail
          ? userInfo.user.registerMail
          : "用户未注册邮箱",
        tel: userInfo.user.phone,
        weibo: "",
        comment:
          "用户卖舱" +
          "起始港:" +
          row.startSeaport +
          "目的港:" +
          row.targetSeaport,
      });
      _MEIQIA("showPanel");
    },
  },
};
</script>
<style scoped lang="scss">
.userBox {
  box-sizing: border-box;
  background: #fff;
  padding-bottom: 40px;
  padding: 20px;
}
.main {
  background: #f9f9f9;
  padding-bottom: 100px;
  padding-left: 160px;
  padding-right: 160px;
}
.youxuanBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  font-weight: 900;
  font-size: 20px;
}
.hengxian {
  width: 200px;
  height: 2px;
  margin: 0px auto;
  padding: 0px;
  background-color: #d5d5d5;
  overflow: hidden;
}
.pagination {
  text-align: center;
  width: 40%;
  left: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;

}

.quedingdiv {
  width: 50px;
  height: 32px;
  background: #1677ff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  line-height: 32px;
  text-align: center;
  margin-left: 8px;
  color: #fff;
  cursor: pointer;
}
::v-deep .el-table--border {
  border: none !important;
  background-color: none !important;
}
::v-deep .el-table::before {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
::v-deep .el-table--border::after {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
::v-deep .el-table__body {
  width: 100% !important;
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
::v-deep .page .el-input {
  width: 50px !important;
}
::v-deep .page .el-input__inner {
  width: 50px !important;
}

::v-deep .el-table th {
  background-color: #f4f4f4;
  height: 29px;
  padding: 0;
  color: #464646;
}
::v-deep .el-table th:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
::v-deep .el-table th:nth-child(6) {
  border-radius: 0 5px 5px 0;
}
.contectPer {
  width: 61px;
  height: 25px;
  border: 1px solid #00bafd;
  color: #00bafd;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 5px;
}
.btnXun {
  height: 0px;
  .xuncang {
    position: absolute;
    right: 40px;
    bottom: 30px;
    background-color: #00bafd;
    font-size: 14px;
    color: #fff;
    width: 82px;
    line-height: 33px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
}
::v-deep .el-table__empty-text{
    display: none;
}
.noImg{
  position: absolute;
  width: 260px;
  height: 190px;
  top: 25%;
  left: 32%;
}
h2{
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 18px;
}
::v-deep .el-dialog{
  border-radius: 10px !important;
}
.mb-2{
  margin-bottom: 10px;
  margin-left: 30px;
}
</style>